import React, {Component} from "react"
import {Routes, Route, Link} from "react-router-dom";
import AboutPage from "./AboutPage"
import LovePage from "./LovePage"
import ArticlePage from "./ArticlePage"
import PromoPage from "./PromoPage"
import sweet_stain_logo from "../assets/sweet_stain_taller.gif"
import SpecifcArticle from "./SpecificArticle"
import 'animate.css';


export default class BasePage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            land_hover:"Welcome to Sweet Stain!",
            landPage:true,
            aboutPage:false,
            lovePage:false,
            articlePage:false,
            promoPage:false,
            specificArticlePage:false,
            SpecificArticle:"",
            praiseArray:["service","awe","reverence"],
            article_list: {
                article_5:{
                    title:"Manufacturing_Ethics",
                    author:"Ali",
                    authour_link:"https://www.instagram.com/slimenbile/",
                    abstract:"Doublespeak gilds our screen as our gaze dances around household Fashion names. Thick air begins to creep into the periphery, slowly obscuring...",
                    image_link:"",
                    date:"05/03/23",
                    tags:[""],
                    hover_off:"We Pay We Say",
                    pdf_link:"https://www.dropbox.com/s/8vmdd6535s2w7ir/sweet-stain_writing_manufacturing_ethics.pdf?dl=0"
                },
                article_4:{
                    title:"Writing_sustainably",
                    author:"Jean-Paul",
                    authour_link:"https://www.instagram.com/jp0101/",
                    abstract:"To transform dreams into materia. Visions of the past, present, and future turned into tangible objects. An eternal routine...",
                    image_link:"",
                    date:"23/02/23",
                    tags:[""],
                    hover_off:"Holy Sustainability",
                    pdf_link:"https://www.dropbox.com/s/stleaynbwt1qbu6/sweet-stain_writing_sustainably.pdf?dl=0"
                },
                article_3:{
                    title:"Massively_Multiplayer_Fashion",
                    author:"Ali",
                    authour_link:"https://www.instagram.com/slimenbile/",
                    abstract:"To mentally equip our readers for this grindset Fashion world we have devised a brand-build-guide.",
                    image_link:"",
                    date:"11/02/23",
                    tags:[""],
                    hover_off:"Brand Grind",
                    pdf_link:"https://www.dropbox.com/s/ogfhf7su7jp0sia/sweet-stain_massively_multiplyer_fashion.pdf?dl=0"
                },
                article_2:{
                    title:"Exploiting_cultural_acceleration",
                    author:"Jean-Paul",
                    authour_link:"https://www.instagram.com/jp0101/",
                    abstract:"A brand that has appeared seemingly out of nowhere is now sitting alone at the top of our favorite fast-fashion conglomerates including H&M and Inditex...",
                    image_link:"",
                    date:"30/01/23",
                    tags:[""],
                    hover_off:"Shein Shine",
                    pdf_link:"https://www.dropbox.com/s/7kdh1vrdv7hkcx9/sweet-stain_exploiting_cultural_acceleration.pdf?dl=0"
                },
                article_1:{
                    title:"Rag_Reanimation",
                    author:"Ali",
                    authour_link:"https://www.instagram.com/slimenbile/",
                    abstract:"Now join us, as we categorically dismantle & investigate the festering corpse that is, the second hand luxury clothing resale marketplace.",
                    image_link:"",
                    date:"30/01/23",
                    tags:[],
                    hover_off:"Retail necromancy",
                    pdf_link:"https://www.dropbox.com/s/0yn78os6e0yb6bt/sweet-stain_rag_reanimate.pdf?dl=0"
                }
            }
        };
        this.toggleLandPage = this.toggleLandPage.bind(this);
        this.toggleAboutPage = this.toggleAboutPage.bind(this);
        this.toggleLovePage = this.toggleLovePage.bind(this);
        this.toggleArticlePage = this.toggleArticlePage.bind(this);
        this.togglePromoPage = this.togglePromoPage.bind(this);
        this.toggleSpecificArticle = this.toggleSpecificArticle.bind(this);
        this.UrlRouter = this.UrlRouter.bind(this);
    }
    
    componentDidMount(){
        this.UrlRouter()
        window.addEventListener('popstate', (event) => {
            this.UrlRouter()
        });
        window.addEventListener('pushstate', (event) => {
            this.UrlRouter()
        });
    }
    
    UrlRouter(){
        if (window.location.href===window.location.origin + "/") {
            this.toggleLandPage()
        }
        if (window.location.href===window.location.origin + "/about") {
            this.toggleAboutPage()
        }
        if (window.location.href===window.location.origin + "/love") {
            this.toggleLovePage()
        }
        if (window.location.href===window.location.origin + "/article") {
            this.toggleArticlePage()
        }
        if (window.location.href===window.location.origin + "/promo") {
            this.togglePromoPage()
        }
        for (const key in this.state.article_list) {
            if (window.location.href===window.location.origin + "/" + this.state.article_list[key]["title"]) {
                this.toggleSpecificArticle(this.state.article_list[key]["title"])
            }
        }
    }

    toggleLandPage() {
        this.setState({
            landPage: true,
            aboutPage:false,
            lovePage:false,
            articlePage:false,
            promoPage:false,
            specificArticlePage:false
        });
    }

    toggleAboutPage() {
        this.setState({
            landPage: false,
            aboutPage:true,
            lovePage:false,
            articlePage:false,
            promoPage:false,
            specificArticlePage:false
        });
    }
      
    toggleLovePage() {
        this.setState({
            landPage: false,
            aboutPage:false,
            lovePage:true,
            articlePage:false,
            promoPage:false,
            specificArticlePage:false
        });
    }
      
    toggleArticlePage() {
        this.setState({
            landPage: false,
            aboutPage:false,
            lovePage:false,
            articlePage:true,
            promoPage:false,
            specificArticlePage:false
        });
    }
      
    togglePromoPage() {
        this.setState({
            landPage: false,
            aboutPage:false,
            lovePage:false,
            articlePage:false,
            promoPage:true,
            specificArticlePage:false
        });
    }

    toggleSpecificArticle(articlename) {
        this.setState({
            landPage: false,
            aboutPage:false,
            lovePage:false,
            articlePage:false,
            promoPage:false,
            specificArticlePage:true,
            SpecificArticle:articlename
        });
    }
    
      
    render() {
        var landvisibility="show"
        if (this.state.landPage) {
            landvisibility="show"
        } else {
            landvisibility="hide"
        }

        const colors = ["#D1D1D14C", "#BAEACD4C", "#DEA2BB4C", "#E8CA514C", "#F2F3B64C"];
        window.addEventListener("mousedown", (e) => {
            const color = colors.shift();
            document.documentElement.style.setProperty("--highlight-color", color);
            colors.push(color);
          });

        var praiseWord = this.state.praiseArray[this.state.praiseArray.length * Math.random() | 0];

        var article_routes = []
        for (const key in this.state.article_list) {
            article_routes.push (
                <Route key={"/"+this.state.article_list[key]["title"]+"_route"} path={this.state.article_list[key]["title"]} element={<SpecifcArticle toggleLandPage={this.toggleLandPage} toggleAboutPage={this.toggleAboutPage} toggleLovePage={this.toggleLovePage} togglePromoPage={this.togglePromoPage} toggleArticlePage={this.toggleArticlePage} specificArticlePage={this.state.specificArticlePage} landPage={this.state.landPage} aboutPage={this.state.aboutPage} lovePage={this.state.lovePage} articlePage={this.state.articlePage} PromoPage={this.state.promoPage} SpecificArticle={this.state.SpecificArticle} article_list={this.state.article_list}/>}/>
            )
        }

        return (
            <div className="App">
                <div id="landPageHold" className={landvisibility}>
                    <div className="landHeader">
                        <a href="https://www.instagram.com/sweet_stain_/" target="_blank" rel="noreferrer">
                            <div className="landInstaCont" onMouseEnter={() => this.setState({land_hover:"What you'd expect"})} onMouseLeave={() => this.setState({land_hover:"Welcome to Sweet Stain!"})}>
                                <div className="landInsta"></div>
                            </div>
                        </a>
                        <div className="landInfoCont">
                            <div className="landInfo" onMouseEnter={() => this.setState({land_hover:"Nothing to see here"})} onMouseLeave={() => this.setState({land_hover:"Welcome to Sweet Stain!"})}><p className="hovertext">{this.state.land_hover}</p></div>
                        </div>
                    </div>
                    <div className="landPagesCont">
                        <div className="landPageLandCont" onMouseEnter={() => this.setState({land_hover:"Where you've landed!"})} onMouseLeave={() => this.setState({land_hover:"Welcome to Sweet Stain!"})}><div className="landPageLogo animate__animated animate__tada animate__infinite"></div></div>
                        <div className="landOtherPageCont">
                            <Link to ="/about"><div className="landAbout" onClick={() => {this.toggleAboutPage();}} onMouseEnter={() => this.setState({land_hover:"More about Sweet Stain"})} onMouseLeave={() => this.setState({land_hover:"Welcome to Sweet Stain!"})}><div className="landAboutLogo animate__animated animate__tada animate__infinite"></div></div></Link>
                            <Link to ="/love"><div className="landLove" onClick={() => {this.toggleLovePage();}} onMouseEnter={() => this.setState({land_hover:"Articles we recommend"})} onMouseLeave={() => this.setState({land_hover:"Welcome to Sweet Stain!"})}><div className="landLoveLogo animate__animated animate__heartBeat animate__infinite"></div></div></Link>
                            <Link to ="/article"><div className="landArticle" onClick={() => {this.toggleArticlePage();}} onMouseEnter={() => this.setState({land_hover:"Our own writing"})} onMouseLeave={() => this.setState({land_hover:"Welcome to Sweet Stain!"})}><div className="landArticlesLogo animate__animated animate__jello animate__infinite"></div></div></Link>
                            <Link to ="/promo"><div className="landPromo" onClick={() => {this.togglePromoPage();}} onMouseEnter={() => this.setState({land_hover:"Contact & other projects!"})} onMouseLeave={() => this.setState({land_hover:"Welcome to Sweet Stain!"})}><div className="landPromoLogo animate__animated animate__headShake animate__infinite"></div></div></Link>
                        </div>
                    </div>
                    <div className="landContentCont">
                        <div className="landContent">
                            <div className="landSSsummary">In <i>{praiseWord}</i> of Fashion's stains</div>
                            <div className="landWebLogoCont" onMouseEnter={() => this.setState({land_hover:"THIS IS SWEET STAIN!"})} onMouseLeave={() => this.setState({land_hover:"Welcome to Sweet Stain!"})}><div className="landWebLogo"><img src={sweet_stain_logo} alt="sweet stain animation"></img></div></div>
                            <div className="landUpdateCont" onMouseEnter={() => this.setState({land_hover:"Sweet Stain updates"})} onMouseLeave={() => this.setState({land_hover:"Welcome to Sweet Stain!"})}>
                                <u>Update information</u><br/>
                                05/03/23: Manufacturing ethics out !
                            </div>
                        </div>
                    </div>
                </div>
                <Routes>
                    <Route path="/about" element={<AboutPage toggleLandPage={this.toggleLandPage} toggleLovePage={this.toggleLovePage} toggleArticlePage={this.toggleArticlePage} togglePromoPage={this.togglePromoPage} landPage={this.state.landPage} aboutPage={this.state.aboutPage} lovePage={this.state.lovePage} articlePage={this.state.articlePage} PromoPage={this.state.promoPage}/>}/>
                    <Route path="/love" element={<LovePage toggleLandPage={this.toggleLandPage} toggleAboutPage={this.toggleAboutPage} toggleArticlePage={this.toggleArticlePage} togglePromoPage={this.togglePromoPage} landPage={this.state.landPage} aboutPage={this.state.aboutPage} lovePage={this.state.lovePage} articlePage={this.state.articlePage} PromoPage={this.state.promoPage}/>}/>
                    <Route path="/article" element={<ArticlePage toggleLandPage={this.toggleLandPage} toggleAboutPage={this.toggleAboutPage} toggleLovePage={this.toggleLovePage} togglePromoPage={this.togglePromoPage} toggleArticlePage={this.toggleArticlePage} toggleSpecificArticle={this.toggleSpecificArticle} landPage={this.state.landPage} aboutPage={this.state.aboutPage} lovePage={this.state.lovePage} articlePage={this.state.articlePage} PromoPage={this.state.promoPage} specificArticlePage={this.state.specificArticlePage} article_list={this.state.article_list}/>}/>
                    {article_routes}
                    <Route path="/promo" element={<PromoPage toggleLandPage={this.toggleLandPage} toggleAboutPage={this.toggleAboutPage} toggleLovePage={this.toggleLovePage} toggleArticlePage={this.toggleArticlePage} landPage={this.state.landPage} aboutPage={this.state.aboutPage} lovePage={this.state.lovePage} articlePage={this.state.articlePage} PromoPage={this.state.promoPage}/>}/>
                </Routes>
            </div>
        );
      
    }
}