import React, {Component} from "react";
import "../css/global.css";
import {Link} from "react-router-dom";

export default class AboutPage extends Component {
constructor(props, context) {
  super(props, context);
  this.state = {
    visible: false,
    about_hover:"Stuff about us",
    love_run:Math.floor(Math.random() * (6 - 3 + 1)) + 3,
    artice_run:Math.floor(Math.random() * (6 - 3 + 1)) + 3,
    promo_run:Math.floor(Math.random() * (6 - 3 + 1)) + 3,
    landing_run:Math.floor(Math.random() * (6 - 3 + 1)) + 3,
    about_run:Math.floor(Math.random() * (6 - 3 + 1)) + 3
  };
}

render() {
    var aboutvisibility="hide"
    if (this.props.aboutPage) {
        aboutvisibility="show"
    } else {
        aboutvisibility="hide"
    }

    return (
    <div id="aboutPageHold" className={aboutvisibility}>
        <div id="aboutContainer">
            <div className="aboutPagesContVert">
                <Link to="/love"><div className="aboutPageLove" onClick={() => {this.props.toggleLovePage();}} onMouseEnter={() => this.setState({about_hover:"Stuff we love"})} onMouseLeave={() => this.setState({about_hover:"Stuff about us"})}><div className="aboutLoveLogo" style={{animationDuration: this.state.love_run+"s"}}></div><div className="aboutLoveLogo_delay" style={{animationDuration: this.state.love_run+"s"}}></div></div></Link>
                <Link to="/article"><div className="aboutPageArticle" onClick={() => {this.props.toggleArticlePage();}} onMouseEnter={() => this.setState({about_hover:"Stuff we wrote"})} onMouseLeave={() => this.setState({about_hover:"Stuff about us"})}><div className="aboutArticleLogo" style={{animationDuration: this.state.artice_run+"s"}}></div><div className="aboutArticleLogo_delay" style={{animationDuration: this.state.artice_run+"s"}}></div></div></Link>
            </div>
            <div className="aboutHeader">
                <a href="https://www.instagram.com/sweet_stain_/" target="_blank" rel="noreferrer">
                    <div className="aboutInstaCont" onMouseEnter={() => this.setState({about_hover:"Check out the insta"})} onMouseLeave={() => this.setState({about_hover:"Stuff about us"})}>
                        <div className="aboutInsta"></div>
                    </div>
                </a>
                <div className="aboutInfoCont">
                    <div className="aboutInfo"><p className="hovertext">{this.state.about_hover}</p></div>
                </div>
            </div>
            <div className="aboutPagesContHori">
                <Link to="/promo"><div className="aboutPagePromo" onClick={() => {this.props.togglePromoPage();}} onMouseEnter={() => this.setState({about_hover:"Our other stuff"})} onMouseLeave={() => this.setState({about_hover:"Stuff about us"})}><div className="aboutPromoLogo" style={{animationDuration: this.state.promo_run+"s"}}></div><div className="aboutPromoLogo_delay" style={{animationDuration: this.state.promo_run+"s"}}></div></div></Link>
                <Link to="/"><div className="aboutPageLanding" onClick={() => {this.props.toggleLandPage();}} onMouseEnter={() => this.setState({about_hover:"Where people land"})} onMouseLeave={() => this.setState({about_hover:"Stuff about us"})}><div className="aboutLandLogo" style={{animationDuration: this.state.landing_run+"s"}}></div><div className="aboutLandLogo_delay" style={{animationDuration: this.state.landing_run+"s"}}></div></div></Link>
                <div className="aboutPageAbout" onMouseEnter={() => this.setState({about_hover:"Where you're at!"})} onMouseLeave={() => this.setState({about_hover:"Stuff about us"})}><div className="aboutPageLogo" style={{animationDuration: this.state.about_run+"s"}}></div><div className="aboutPageLogo_delay" style={{animationDuration: this.state.about_run+"s"}}></div></div>
            </div>
            <div className="aboutContentCont">
                <div className="aboutContent">
                    <div className="aboutContentText">Sweet Stain renders Fashion through the lens of Online Luxury Clothing culture in a form which can scarcely be discerned from shower thoughts</div>
                    <div className="aboutContentLogo"></div>
                    <div className="aboutContentLinks">
                        <b>Brought to you by:</b><br/><a href="https://alirazzak.info/" target="_blank" rel="noreferrer">Ali</a> & <a href="https://www.instagram.com/jp0101/" target="_blank" rel="noreferrer">Jean-Paul</a>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
}
