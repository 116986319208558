import React, {Component} from "react";
import "../css/global.css";
import {Link} from "react-router-dom";
import 'animate.css';

export default class LovePage extends Component {
constructor(props, context) {
  super(props, context);
  this.state = {
    visible: false,
    love_hover:"Stuff we think is rad",
    about_scroll:Math.floor(Math.random() * (5 - 2 + 1)) + 2,
    landing_scroll:Math.floor(Math.random() * (5 - 2 + 1)) + 2,
    articles_scroll:Math.floor(Math.random() * (5 - 2 + 1)) + 2,
    promo_scroll:Math.floor(Math.random() * (5 - 2 + 1)) + 2
  };
}

render() {
    var lovevisibility="hide"
    if (this.props.lovePage) {
        lovevisibility="show"
    } else {
        lovevisibility="hide"
    }

    return (
    <div id="lovePageHold" className={lovevisibility}>
        <div className="loveHeader">
            <a href="https://www.instagram.com/sweet_stain_/" target="_blank" rel="noreferrer">
                <div className="loveInstaCont" onMouseEnter={() => this.setState({love_hover:"Insta stuff to love"})} onMouseLeave={() => this.setState({love_hover:"Stuff we think is rad"})}>
                    <div className="loveInsta"></div>
                </div>
            </a>
            <div className="loveInfoCont">
                <div className="loveInfo"><p className="hovertext">{this.state.love_hover}</p></div>
            </div>
        </div>
        <div className="lovePagesCont">
            <div className="loveOtherPageCont">
            <Link to ="/"><div className="loveLand" onClick={() => {this.props.toggleLandPage();}} onMouseEnter={() => this.setState({love_hover:"The landing page"})} onMouseLeave={() => this.setState({love_hover:"Stuff we think is rad"})}><div className="loveLandLogo" style={{animationDuration: this.state.landing_scroll+"s"}}></div></div></Link>
                <Link to ="/about"><div className="loveAbout" onClick={() => {this.props.toggleAboutPage();}} onMouseEnter={() => this.setState({love_hover:"More about Sweat Stain"})} onMouseLeave={() => this.setState({love_hover:"Stuff we think is rad"})}><div className="loveAboutLogo" style={{animationDuration: this.state.about_scroll+"s"}}></div></div></Link>
                <Link to ="/article"><div className="loveArticle" onClick={() => {this.props.toggleArticlePage();}} onMouseEnter={() => this.setState({love_hover:"Our own rad stuff"})} onMouseLeave={() => this.setState({love_hover:"Stuff we think is rad"})}><div className="loveArticleLogo" style={{animationDuration: this.state.articles_scroll+"s"}}></div></div></Link>
                <Link to ="/promo"><div className="lovePromo" onClick={() => {this.props.togglePromoPage();}} onMouseEnter={() => this.setState({love_hover:"More about us!"})} onMouseLeave={() => this.setState({love_hover:"Stuff we think is rad"})}><div className="lovePromoLogo" style={{animationDuration: this.state.promo_scroll+"s"}}></div></div></Link>
            </div>
            <div className="lovePageLandCont" onMouseEnter={() => this.setState({love_hover:"Where you're at!"})} onMouseLeave={() => this.setState({love_hover:"Stuff we think is rad"})}><div className="lovePageLogo animate__animated animate__heartBeat animate__infinite"></div></div>
        </div>
        <div className="loveContentCont">
            <div className="loveContentBoxCont">
                <div className="LoveContentBox">
                    <a href="https://www.amazon.com/Fetish-Fashion-Power-Valerie-Steele/dp/0195115791" target="_blank" rel="noreferrer">
                        <div className="LoveContentImage" style={{backgroundImage:"url(https://m.media-amazon.com/images/I/51Lfu4shJSL._SX348_BO1,204,203,200_.jpg)"}}></div>
                        <div className="LoveContentTitle">Fetish: Fashion, Sex & Power</div>
                    </a>
                </div>
            </div>
            <div className="loveContentBoxCont">
                <a href="https://www.goodreads.com/book/show/26636564-craft-of-use" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1451245012l/26636564.jpg)"}}></div>
                        <div className="LoveContentTitle">Craft of Use: Post-Growth Fashion</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="https://www.goodreads.com/book/show/36800564-fashion-and-materialism?ref=nav_sb_ss_3_13" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1591625685l/36800564.jpg)"}}></div>
                        <div className="LoveContentTitle">Fashion and Materialism</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="https://www.goodreads.com/book/show/52392631-the-psychopolitics-of-fashion?ref=nav_sb_ss_1_25" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1572996539l/52392631._SX318_SY475_.jpg)"}}></div>
                        <div className="LoveContentTitle">The Psychopolitics of Fashion: Conflict and Courage Under the Current State of Fashion</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="https://stackoverflow.com/questions/34331351/how-to-resize-animated-gif-with-html-css" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(http://vestoj.com/app/uploads/2018/08/cheryldonegan.jpg)"}}></div>
                        <div className="LoveContentTitle">WHAT DOES AN ARTIST WEAR?</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="https://thenewinquiry.com/spinoza-in-a-t-shirt/" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(https://thenewinquiry.com/app/uploads/2015/06/spinoza-social.jpg)"}}></div>
                        <div className="LoveContentTitle">Spinoza in a T-Shirt</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="https://www.theguardian.com/fashion/2020/jun/14/margaret-howell-50-years-design-katharine-hepburn" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(https://i.guim.co.uk/img/media/85ceb82e8fc86ca40d78d67f86eabac18dc7bc5d/0_1590_3500_2100/master/3500.jpg?width=1300&quality=85&dpr=1&s=none)"}}></div>
                        <div className="LoveContentTitle">Better with age: the enduring fashion philosophy of Margaret Howell</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="http://vestoj.com/the-instagrammable-shopping-centre/" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(http://vestoj.com/app/uploads/2020/09/W1siZiIsIjM5OTU2MyJdLFsicCIsImNvbnZlcnQiLCItcXVhbGl0eSA5MCAtcmVzaXplIDE0NDB4MTA4MFx1MDAzZSJdXQ-768x1019.jpg)"}}></div>
                        <div className="LoveContentTitle">THE INSTAGRAMMABLE SHOPPING CENTRE</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="https://www.theguardian.com/fashion/2020/sep/09/now-stalgia-why-fashion-is-going-back-to-the-future?CMP=Share_AndroidApp_Other" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(https://i.guim.co.uk/img/media/5561b09ac862f9b738f64cf7fae2232ad4df9d53/0_0_1437_968/master/1437.jpg?width=620&quality=85&dpr=1&s=none)"}}></div>
                        <div className="LoveContentTitle">Now-stalgia: why fashion is going back to the future</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="https://www.vice.com/en/article/akzkvp/international-fashion-houses-are-leaving-millions-of-asians-jobless-now-the-workers-are-protesting" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(https://video-images.vice.com/test-uploads/articles/5ef4b8d645690a00963d70de/lede/1593096427418-000_1C331X.jpeg?crop=1xw:0.8442xh;0xw,0.1558xh&resize=500:*)"}}></div>
                        <div className="LoveContentTitle">International Fashion Houses Are Leaving Millions of Asians Jobless. Now the Workers Are Protesting</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="http://vestoj.com/what-fashion-is-not-only/" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(http://vestoj.com/app/uploads/2020/03/Screen-Shot-2020-03-23-at-15.23.40.png)"}}></div>
                        <div className="LoveContentTitle">WHAT FASHION IS NOT (ONLY)</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="https://www.amazon.com/About-Face-Performing-Fashion-Theater/dp/0415911419" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(https://m.media-amazon.com/images/I/41rf6wrPTWL._SX331_BO1,204,203,200_.jpg)"}}></div>
                        <div className="LoveContentTitle">About Face: Performing Race in Fashion and Theater</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="https://www.amazon.com/Adorned-Dreams-Modernity-Elizabeth-Wilson/dp/0813533333" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(https://m.media-amazon.com/images/I/513JCAEQ9FL._SX310_BO1,204,203,200_.jpg)"}}></div>
                        <div className="LoveContentTitle">Adorned in Dreams</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="http://vestoj.com/the-life-of-a-garment/" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(http://vestoj.com/app/uploads/2020/01/Screen-Shot-2019-12-02-at-13.59.22.png)"}}></div>
                        <div className="LoveContentTitle">THE LIFE OF A GARMENT</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="https://www.gq.com/story/dries-van-noten-interview-spring-summer-2018" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(https://media.gq.com/photos/5aa6bcffda6c277bbb5d9f79/16:9/w_2560%2Cc_limit/labels-on-fire-dries-van-noten-gq-style-18-01.jpg)"}}></div>
                        <div className="LoveContentTitle">Dries Van Noten Talks Staying Independent and Designing In the Instagram Age</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="http://vestoj.com/model-status/" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(http://vestoj.com/app/uploads/2019/10/Screen-Shot-2019-10-14-at-14.06.40.png)"}}></div>
                        <div className="LoveContentTitle">MODEL STATUS</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="http://vestoj.com/schrodingers-jeans/" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(http://vestoj.com/app/uploads/2019/02/H-Eijkelboom.jpg)"}}></div>
                        <div className="LoveContentTitle">SCHRÖDINGER’S JEANS</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="http://vestoj.com/thats-not-me/" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(http://vestoj.com/app/uploads/2019/04/Screen-Shot-2019-04-23-at-18.11.14.png)"}}></div>
                        <div className="LoveContentTitle">THAT’S NOT ME</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="https://www.anothermag.com/fashion-beauty/11486/cover-story-rare-interview-rei-kawakubo-comme-des-garcons-fashion-designer-2019" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(https://anotherimg-dazedgroup.netdna-ssl.com/1500/azure/another-prod/380/3/383896.jpg)"}}></div>
                        <div className="LoveContentTitle">Cover Story: A Rare Conversation with Rei Kawakubo</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="https://www.businessoffashion.com/articles/fashion-week/remembered-the-game-changing-martin-margiela-show-of-1989/" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(https://cloudfront-eu-central-1.images.arcpublishing.com/businessoffashion/5PBS745KMRFWHNS7K3MAEN7E3A.jpg)"}}></div>
                        <div className="LoveContentTitle">Remembered: The Game-Changing Martin Margiela Show of 1989</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="https://qz.com/quartzy/1160897/why-isnt-streetwear-just-called-fashion" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(https://i.kinja-img.com/gawker-media/image/upload/c_fit,f_auto,g_center,pg_1,q_60,w_1600/9909a7ccf23763a2e15793f85c303e0a.jpg)"}}></div>
                        <div className="LoveContentTitle">Why isn’t streetwear just called “fashion”?</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="https://www.refinery29.com/en-gb/2017/04/149877/fast-fashion-social-issue" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(https://s3.r29static.com/bin/entry/498/340x408,85/1781670/image.webp)"}}></div>
                        <div className="LoveContentTitle">Is Fast Fashion A Class Issue?</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="https://aeon.co/essays/why-in-china-and-japan-a-copy-is-just-as-good-as-an-originalanti-political-aesthetics-objects-and-worlds-beyond" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(https://nu.aeon.co/images/7cd0ddfe-79d6-429b-8a65-0baa73b77dea/header_essay-679740932_master.jpg)"}}></div>
                        <div className="LoveContentTitle">The copy is the original</div>
                    </div>
                </a>
            </div>
            <div className="loveContentBoxCont">
                <a href="http://www.metamute.org/editorial/articles/anti-political-aesthetics-objects-and-worlds-beyond" target="_blank" rel="noreferrer">
                    <div className="LoveContentBox">
                        <div className="LoveContentImage" style={{backgroundImage:"url(https://www.metamute.org/sites/www.metamute.org/files/styles/three-col/public/field/image/_MG_1679.jpg?itok=B-BgeOME)"}}></div>
                        <div className="LoveContentTitle">The Anti-Political Aesthetics of Objects and Worlds Beyond</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
    )
}
}
