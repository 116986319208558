import React, {Component} from "react";
import "../css/global.css";
import {Link} from "react-router-dom";
import contact_logo from "../assets/contact_logo.png"
import love_logo from "../assets/love_logo.png"
import landing_logo from "../assets/landing_logo.png"
import about_logo from "../assets/about_logo.png"
import articles_logo from "../assets/articles_logo.png"

export default class ArticlePage extends Component {
constructor(props, context) {
  super(props, context);
  this.state = {
    visible: false,
    article_hover:"Stuff we wrote !",
    promo_stretch:Math.floor(Math.random() * (5 - 2 + 1)) + 2,
    landing_stretch:Math.floor(Math.random() * (5 - 2 + 1)) + 2,
    about_stretch:Math.floor(Math.random() * (5 - 2 + 1)) + 2,
    love_stretch:Math.floor(Math.random() * (5 - 2 + 1)) + 2,
    article_stretch:Math.floor(Math.random() * (5 - 2 + 1)) + 2,
    article_1:false,
    articleVomit:[]
  };
}


render() {
    var articlevisibility="hide"
    if (this.props.articlePage) {
        articlevisibility="show"
    } else {
        articlevisibility="hide"
    }

    var article_collect = []
    for (const key in this.props.article_list) {
        var article_thumbnail = 'url(' + this.props.article_list[key]["image_link"] + ')'
        let this_article_hover = this.props.article_list[key]["hover_off"]
        article_collect.push (
            <Link key={this.props.article_list[key]["title"]+"_link"} to ={"/"+this.props.article_list[key]["title"]} onClick={() => {this.props.toggleSpecificArticle(this.props.article_list[key]["title"]);}} onMouseEnter={() => this.setState({land_hover:"More about Sweet Stain"})}>
                <div className="articlePagesBoxCont">
                    <div className="articlePagesBox" onMouseEnter={() => this.setState({article_hover:this_article_hover})} onMouseLeave={() => this.setState({article_hover:"Stuff we wrote !"})} onClick={() => {this.props.toggleSpecificArticle(this.props.article_list[key]["title"]);}}>
                        <div className="articleImageCont">
                            <div className="articleImage" style={{backgroundImage: article_thumbnail }}></div>
                        </div>
                        <div className="articleBrief">
                            <div className="articleTitle"><u>{this.props.article_list[key]["title"].replaceAll("_"," ")}</u></div>
                            <div className="articleAuthourDate">
                                <div className="articleAuthour">{this.props.article_list[key]["author"]}</div>
                                <div className="articleDate">{this.props.article_list[key]["date"]}</div>
                            </div>
                            <div className="articleAbs">{this.props.article_list[key]["abstract"]}</div>
                        </div>
                        <div className="ArticleDetails">
                            <div className="articleTags">{this.props.article_list[key]["tags"][0]}<br/>{this.props.article_list[key]["tags"][1]}<br/>{this.props.article_list[key]["tags"][2]}</div>
                        </div>
                    </div>
                </div>
            </Link>
        )
        //console.log(artice_list[this.state.articleList[key]])
    }

    return (
    <div id="articlePageHold" className={articlevisibility}>
        <div id="articleContainer">
            <div className="articlePagesContVert">
                <Link to="/"><div className="articlePageLand" onClick={() => {this.props.toggleLandPage();}} onMouseEnter={() => this.setState({article_hover:"The landing page!"})} onMouseLeave={() => this.setState({article_hover:"Stuff we wrote !"})}><img className="articleLandLogo" src={landing_logo} alt="landing png" style={{animationDuration: this.state.landing_stretch+"s"}}></img></div></Link>
                <Link to="/about"><div className="articlePageAbout" onClick={() => {this.props.toggleAboutPage();}} onMouseEnter={() => this.setState({article_hover:"Sweat Stain stuff"})} onMouseLeave={() => this.setState({article_hover:"Stuff we wrote !"})}><img className="articleAboutLogo" src={about_logo} alt="about png" style={{animationDuration: this.state.about_stretch+"s"}}></img></div></Link>
                <Link to="/love"><div className="articlePageLove" onClick={() => {this.props.toggleLovePage();}} onMouseEnter={() => this.setState({article_hover:"Other cool stuff"})} onMouseLeave={() => this.setState({article_hover:"Stuff we wrote !"})}><img className="articleLoveLogo" src={love_logo} alt="love png" style={{animationDuration: this.state.love_stretch+"s"}}></img></div></Link>
                <Link to="/promo"><div className="articlePagePromo" onClick={() => {this.props.togglePromoPage();}} onMouseEnter={() => this.setState({article_hover:"Intrigue in us"})} onMouseLeave={() => this.setState({article_hover:"Stuff we wrote !"})}><img className="articlePromoLogo" src={contact_logo} alt="contact png" style={{animationDuration: this.state.promo_stretch+"s"}}></img></div></Link>
            </div>
            <div className="articleHeader">
                <a href="https://www.instagram.com/sweet_stain_/" target="_blank" rel="noreferrer">
                    <div className="articleInstaCont" onMouseEnter={() => this.setState({article_hover:"Sweat Stain on the gram"})} onMouseLeave={() => this.setState({article_hover:"Stuff we wrote !"})}>
                        <div className="articleInsta"></div>
                    </div>
                </a>
                <div className="articleInfoCont">
                    <div className="articleInfo" onMouseEnter={() => this.setState({article_hover:"Back up buddy"})} onMouseLeave={() => this.setState({article_hover:"Stuff we wrote !"})}><p className="hovertext">{this.state.article_hover}</p></div>
                </div>
            </div>
            <div className="articlePagesContHori">
                <div className="articlePageArticle" onMouseEnter={() => this.setState({article_hover:"The articles page"})} onMouseLeave={() => this.setState({article_hover:"Stuff we wrote !"})}><img className="articlePageLogo" src={articles_logo} alt="article png" style={{animationDuration: this.state.article_stretch+"s"}}></img></div>
            </div>
        </div>
        <div className="articlePagesContentCont">
            {article_collect}
        </div>
    </div>
    )
}
}
