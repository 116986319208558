import React, {Component} from "react";
import "../css/global.css";
import {Link} from "react-router-dom";
import emailjs from '@emailjs/browser';
import threadbare_logo from "../assets/threadbare_logo.png";
import selfharm_logo from "../assets/self_harm_logo.png";
var moment = require('moment-timezone');


export default class PromoPage extends Component {
constructor(props, context) {
  super(props, context);
  this.state = {
    visible: false,
    contact_hover:"Other avenues",
    land_spin:Math.floor(Math.random() * (4 - 1 + 1)) + 1,
    about_spin:Math.floor(Math.random() * (4 - 1 + 1)) + 1,
    love_spin:Math.floor(Math.random() * (4 - 1 + 1)) + 1,
    article_spin:Math.floor(Math.random() * (4 - 1 + 1)) + 1,
    promo_spin:Math.floor(Math.random() * (4 - 1 + 1)) + 1,
    MessageSubject:"",
    MessageMessage:"",
    MessageSender:""

  };
  this.MessageSubmit = this.MessageSubmit.bind(this);
  this.messageChange = this.messageChange.bind(this);
}

messageChange(event) {
    const target = event.target;
    const value = event.target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

MessageSubmit(event) {
    event.preventDefault();
    var eventMessage = {
        message_subject: this.state.MessageSubject,
        message_message: this.state.MessageMessage,
        message_sender: this.state.MessageSender,
        date: moment().tz('Europe/Stockholm').format('MMMM Do YYYY, h:mm:ss a')
    };
    emailjs.send('service_ov20qof', 'template_kx5atzs', eventMessage, 's6YimfVoxQThyEeWr')
      this.setState({
        MessageSubject: "",
        MessageMessage:"",
        MessageSender:""
      })
      alert('We got it, thanks')
    }
  

render() {
    var promovisibility="hide"
    if (this.props.PromoPage) {
        promovisibility="show"
    } else {
        promovisibility="hide"
    }

    return (
    <div id="promoPageHold" className={promovisibility}>
        <div id="promoContainer">
            <div className="promoPagesContCircleOne">
                <Link to="/"><div className="promoPageLand" onClick={() => {this.props.toggleLandPage();}} onMouseEnter={() => this.setState({contact_hover:"Square one"})} onMouseLeave={() => this.setState({contact_hover:"Our other avenues"})}><div className="promoLandLogo" style={{animationDuration: this.state.land_spin+"s"}}></div></div></Link>
                <Link to="/about"><div className="promoPageAbout" onClick={() => {this.props.toggleAboutPage();}} onMouseEnter={() => this.setState({contact_hover:"Figure us out"})} onMouseLeave={() => this.setState({contact_hover:"Our other avenues"})}><div className="promoAboutLogo" style={{animationDuration: this.state.about_spin+"s"}}></div></div></Link>
            </div>
            <div className="promoHeader">
                <a href="https://www.instagram.com/sweet_stain_/" target="_blank" rel="noreferrer">
                    <div className="promoInstaCont" onMouseEnter={() => this.setState({contact_hover:"Or checkout our Instagram"})} onMouseLeave={() => this.setState({contact_hover:"Our other avenues"})}>
                        <div className="promoInsta"></div>
                    </div>
                </a>
                <div className="promoInfoCont">
                    <div className="promoInfo" onMouseEnter={() => this.setState({contact_hover:"BACK UP!"})} onMouseLeave={() => this.setState({contact_hover:"Our other avenues"})}><p className="hovertext">{this.state.contact_hover}</p></div>
                </div>
            </div>
            <div className="promoPageCont">
                <div className="promoPageLogo" style={{animationDuration: this.state.promo_spin+"s"}} onMouseEnter={() => this.setState({contact_hover:"WHUD UP!"})} onMouseLeave={() => this.setState({contact_hover:"Our other avenues"})}></div>
            </div>
            <div className="promoPagesLinks">
                <div className="promoLinkOne"><div className="promoLinkOneImage" onMouseEnter={() => this.setState({contact_hover:"Find luxury clothing cheap"})} onMouseLeave={() => this.setState({contact_hover:"Our other avenues"})}><a href="https://discord.gg/jSh7n6ju8H" target="_blank" rel="noreferrer"><img src={threadbare_logo} alt="threadbare_logo"/></a></div></div>
                <div className="promoLinkTwo"><div className="promoLinkTwoImage" onMouseEnter={() => this.setState({contact_hover:"Critical fashion chat"})} onMouseLeave={() => this.setState({contact_hover:"Our other avenues"})}><a href="https://discord.gg/wM5sJne" target="_blank" rel="noreferrer"><img src={selfharm_logo} alt="selfharm_logo"/></a></div></div>
            </div>
            <div className="promoPagesContCircleTwo">
                <Link to="/love"><div className="promoPageLove" onClick={() => {this.props.toggleLovePage();}} onMouseEnter={() => this.setState({contact_hover:"Rad stuff"})} onMouseLeave={() => this.setState({contact_hover:"Our other avenues"})}><div className="promoLoveLogo" style={{animationDuration: this.state.love_spin+"s"}}></div></div></Link>
                <Link to="/article"><div className="promoPageArticle" onClick={() => {this.props.toggleArticlePage();}} onMouseEnter={() => this.setState({contact_hover:"Our writing"})} onMouseLeave={() => this.setState({contact_hover:"Our other avenues"})}><div className="promoArticleLogo" style={{animationDuration: this.state.article_spin+"s"}}></div></div></Link>
            </div>
        </div>
        <div className="promoContentBox">
            <div className="promoContentCont">
                <div className="promoContentChange">
                    <div className="promoIntro"><b>HIT US UP</b></div>
                    <form id="MessageForm" onSubmit={this.MessageSubmit}>
                        <label>
                            <b>Subject*</b><br/>
                            <input type="text" className="MessageInput" defaultValue={this.state.MessageSubject} name="MessageSubject" onChange={this.messageChange} required/>
                        </label>
                        <br/>
                        <label>
                            <b>Message*</b><br/>
                            <textarea className="MessageInput_big" name="MessageMessage" defaultValue={this.state.MessageMessage} onChange={this.messageChange} required/>
                        </label>
                        <br/>
                        <label>
                            <b>Your contact</b><br/>
                            <input type="text" className="MessageInput"  defaultValue={this.state.MessageSender} name="MessageSender" onChange={this.messageChange}/>
                        </label>
                        <br/>
                        <input type="submit" className="MessageSubmit"/>
                    </form>
                </div>
            </div>
        </div>
    </div>
    )
}
}
